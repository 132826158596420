import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import Vue from 'vue';
export default Vue.extend({
  name: 'ReadOnlyTextField',
  props: {
    copyToClipboard: Boolean,
    snackbarMessage: String,
    tooltipMessage: String,
    value: String
  },
  data: function data() {
    return {
      // https://github.com/vuejs/vue/issues/5886#issuecomment-512147812
      inputRef: "rotf-".concat(Math.random().toString())
    };
  },
  methods: {
    refIsElement: function refIsElement(ref) {
      return !!(ref && 'select' in ref);
    },
    copySuccess: function copySuccess() {
      void this.$store.dispatch('setSnackbar', {
        message: {
          key: this.snackbarMessage || 'global.copied_to_clipboard'
        }
      });
    },
    fallbackCopy: function fallbackCopy() {
      var copyText = this.$refs[this.inputRef];
      if (this.refIsElement(copyText)) copyText.select();
      // deprecated, but fallback in case the main fn fails
      document.execCommand('copy');
      this.copySuccess();
    },
    copy2ClipboardFrom: function copy2ClipboardFrom() {
      var _navigator$clipboard,
        _this = this;
      // Try to use new clipboard API for copying
      var hasAPI = (_navigator$clipboard = navigator.clipboard) === null || _navigator$clipboard === void 0 ? void 0 : _navigator$clipboard.writeText;
      if (!hasAPI) {
        this.fallbackCopy();
        return;
      }
      navigator.clipboard.writeText(this.value).then(function () {
        _this.copySuccess();
      }, function () {
        _this.fallbackCopy();
      });
    }
  }
});